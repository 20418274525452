/***** Global *****/
body, html {
  color: #8e8e8e;
  background-color: #F6F6F6;
  font-size: 24px;
  font-family: 'Slabo 27px', serif;
}
body ::selection {
	background: #9B111E;
	color: #F6F6F6;
}
body ::-moz-selection {
	background: #9B111E;
	color: #F6F6F6;
}

/***** Header *****/
.header {
  margin-top: 50px;
}
.header img {
  max-width: 300px;
}
.connect a {
  transition: color 0.5s ease;
  color: #8e8e8e;
}
.connect a:hover {
  color: #9B111E;
  text-decoration: none;
}

/***** Main *****/
.about p {
  margin-bottom: 25px;
}
.about a {
  transition: background-color 0.5s ease;
  transition: color 0.5s ease;
  background-color: #dcdcdc;
  color: #8e8e8e;
}
.about a:hover {
  background-color: #a9a9a9;
  color: #F6F6F6;
  text-decoration: none;
}

/***** Footer *****/
footer {
  color: #a9a9a9;
  font-size: 12px;
  margin-bottom: 50px;
}

footer a {
  color: #a9a9a9;
  text-decoration: underline;
}

footer a:hover {
  color: #8e8e8e;
}

/***** Overrides ******/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
